import http from "./http";
import { CompanyLogin } from "../interface/admin";
const AWS = require('aws-sdk'); // import AWS from 'aws-sdk';   // import AWS from 'aws-sdk';
const s3 = new AWS.S3({});

export const uploadCompanyImage = async (convertedFile: any, imageName: string) => {
	await http.put(
		`upload`, 
		{
			file: convertedFile, 
			imageName: imageName 
		}
	);
};

export const LoginCompany = async (loginInfo: CompanyLogin) => { 
	try {
		const Login = await http.post(`/company/login`,loginInfo);
		sessionStorage.setItem("token", Login.data.token);
		return Login
	} catch (e:any) {
		return e.response;
	}
};

export const getCompanyList = async ()=>{
	try {
		const companyList = await http.get(`/company`);
		return companyList.data
	} catch (e:any) {
		return e.response;
	}
}

export const getAWSCreds = async ()=>{
	try {
		const companyList = await http.get(`/company/getAWSCreds`);
		return companyList.data
	} catch (e:any) {
		return e.response;
	}
}



export const updateCompany = async (obj:any)=>{
	try {
		const response = await http.put('/company/edit-company',obj)
		return response;
	}catch(e){
		console.log(e);
	}
};
export const addCompany = async (obj:any)=>{
	try {
		const response = await http.post('/company/add-company',obj)
		return response;
	}catch(e){
		console.log(e);
	}
};

export const deleteMultipleCompanies = async (data:any, mutate:Function) => {
    try {
		const response = await http.post(`/company/delete-multiple`, {data:data});
        mutate();
		return response;
	} catch (e) {
		console.log(e);
	}
};

export const updateMultipleStatus = async (data:any, status:string, mutate:Function) => {
    try {
		const response = await http.put(`/company/update-multiple-status`, {data:data, status:status});
		mutate();
		return response;
	} catch (e) {
		console.log(e);
	}
};